.hero {
  padding-top: 125px;
  --padding-bottom: 125px;

  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url('/assets/images/hero.png');

  &-header {
    width: fit-content;

    display: flex;
    flex-direction: column;
    row-gap: 40px;

    color: #FAF4FF;

    @include max-width($breakpoint-tablet-lg) {
      padding-bottom: 0;
    }

    &__title {
      font-size: 64px;
      font-weight: 600;
    }

    &__subtitle {
      font-size: 28px;
    }
  }

  &:not(:has(.hero-cards-wrapper)) {
    padding-bottom: 125px;
    --padding-bottom: 125px;

    @include max-width($breakpoint-tablet-lg) {
      padding-bottom: 80px;
      --padding-bottom: 80px;
    }

    @include max-width($breakpoint-mobile-lg) {
      padding-bottom: 70px;
      --padding-bottom: 70px;
    }
  }

  &-cards {
    position: absolute;
    translate: 0 -50%;

    display: flex;
    gap: 20px;

    width: 100%;

    @include max-width($breakpoint-tablet-lg) {
      position: static;
      flex-wrap: wrap;
      translate: none;
      margin-top: 50px;
    }

    &-wrapper {
      position: relative;
    }

    &__text-item {
      flex-basis: 25%;

      font-family: 'Source Serif 4', serif;
      font-size: 46px;

      color: #FFF;

      @include max-width($breakpoint-tablet-lg) {
        flex-basis: 100%;
        font-size: 40px;
      }

      @include max-width($breakpoint-mobile-lg) {
        font-size: 36px;
      }
    }

    &__item {
      padding: 40px;
      flex-grow: 1;

      border-radius: 20px;
      background-color: rgba(242, 234, 255, 0.6);

      backdrop-filter: blur(35px);
      box-shadow: 0px 18px 40px 0px rgba(39, 0, 107, 0.18);

      &:has(.hero-card-2) {
        flex-basis: 25%;

        @include max-width($breakpoint-tablet-lg) {
          flex-basis: calc(50% - 10px);
        }

        @include max-width($breakpoint-mobile-lg) {
          flex-basis: 100%;
        }
      }
    }
  }

  @include max-width($breakpoint-tablet-lg) {
    padding-top: 80px;
    padding-bottom: 80px;

    &-header {
      row-gap: 30px;

      &__title {
        font-size: 54px;
      }

      &__subtitle {
        font-size: 24px;
      }
    }

    &-cards {
      &__item {
        padding: 30px;
      }
    }
  }

  @include max-width($breakpoint-tablet-md) {
    &-header {
      row-gap: 20px;

      &__title {
        font-size: 40px;
      }

      &__subtitle {
        font-size: 20px;
      }
    }
  }

  @include max-width($breakpoint-mobile-lg) {
    padding-top: 70px;
    padding-bottom: 70px;

    &-header {
      &__title {
        font-size: 36px;
      }

      &__subtitle {
        font-size: 18px;
      }
    }

    &-cards {
      &__item {
        padding: 25px;
      }
    }
  }
}
