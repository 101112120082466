.page.page_researches {
  .hero + .text-block {
    padding-block: 120px;
    background-color: #FAF4FF;

    @include max-width($breakpoint-tablet-lg) {
      padding-block: 90px;
    }

    @include max-width($breakpoint-mobile-lg) {
      padding-block: 80px;
    }

    @include max-width($breakpoint-mobile-md) {
      padding-block: 70px;
    }
  }

  .hero + .text-block,
  .text-block + .scope-of-work,
  .scope-of-work + .projects,
  .projects + .consultation,
  .consultation + .text-block {
    margin-top: var(--block-space-between);
  }
}
