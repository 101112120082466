.input {
  padding: 15px 30px;

  color: #000;
  background-color: #FAF4FF;

  border-radius: 50px;
  caret-color: #AF63FE;

  &:hover,
  &:focus-visible {
    background-color: #FFF;
  }

  &::placeholder {
    opacity: 1;
    color: #8265B5;
  }

  &_invalid {
    color: #F46969;
    background-color: #FAF4FF;

    &::placeholder {
      color: #F46969;
    }
  }

  &_sm {
    padding-block: 10px;
    font-size: 14px;
  }
}
