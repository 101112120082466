.pagination {
  display: flex;
  column-gap: 20px;

  &__button,
  &__item {
    padding: 5px 10px;
    border-radius: 6px;
  }

  &__button {
    display: flex;
    color: #FFF;
    background-color: #8265b5;

    svg {
      margin: auto;
      width: 10px;
      height: 15px;
    }
  }

  &__item {
    color: #231f29;
    background-color: #faf4ff;
  }
}
