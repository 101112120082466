.blog {
  &__title {
    font-size: 60px;
    font-weight: 400;
    color: #231f29;

    @include max-width($breakpoint-tablet-lg) {
      font-size: 46px;
    }

    @include max-width($breakpoint-mobile-lg) {
      font-size: 36px;
    }
  }

  &__divider {
    margin-block: 40px;
  }

  &__body {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 70px 20px;

    @include max-width($breakpoint-tablet-lg) {
      grid-template-columns: repeat(2, 1fr);
    }

    @include max-width($breakpoint-mobile-lg) {
      grid-template-columns: 1fr;
    }
  }

  &-article {
    &__image {
      width: 100%;
      aspect-ratio: 1;
      object-fit: cover;
    }

    &__body {
      margin-top: 20px;
      padding-inline: 20px;
    }

    &__date {
      font-size: 12px;
      font-weight: 400;
      color: #c7b6e4;
    }

    &__title {
      margin-top: 10px;
      font-size: 24px;
      font-weight: 600;
      color: #231f29;
    }

    &__description {
      margin-top: 20px;
    }

    &-link {
      margin-top: 30px;

      display: flex;
      align-items: center;
      column-gap: 10px;

      font-size: 14px;
      color: #681bb6;

      &__icon {
        flex-shrink: 0;

        width: 20px;
        height: 15px;

        svg {
          width: 100%;
          height: 100%;
        }
      }
    }
  }

  &-footer {
    margin-top: 40px;
    padding-top: 40px;

    display: flex;
    align-items: center;
    justify-content: space-between;

    border-top: 2px solid var(--accent-color);

    @include max-width($breakpoint-mobile-lg) {
      flex-direction: column;

      &__button {
        margin-top: 30px;
        width: 100%;
      }
    }
  }
}
