@import './base/reset';
@import './base/typography';

@import './abstracts/variables';
@import './abstracts/mixins';

@import './layouts/container';
@import './layouts/header';
@import './layouts/footer';

@import './components/ui/button';
@import './components/ui/divider';
@import './components/ui/list';
@import './components/ui/pagination';
@import './components/ui/input';
@import './components/tabs';
@import './components/accordion';
@import './components/ui/table';
@import './components/hamburger-menu';
@import './components/ui/dropdown';

@import './components/hero-cards/hero-card-1';
@import './components/hero-cards/hero-card-2';

@import './sections/hero';
@import './sections/projects';
@import './sections/team';
@import './sections/blog';
@import './sections/consultation';
@import './sections/tags';
@import './sections/text-block';
@import './sections/scope-of-work';
@import './sections/channels';
@import './sections/areas-of-work';

@import './sections/article';

@import './pages/home';
@import './pages/researches';
@import './pages/marketing';
@import './pages/digital-body';
@import './pages/countryside-hotel';
@import './pages/pricelist';
@import './pages/about-us';
@import './pages/portfolio';
@import './pages/blog';
@import './pages/article';
@import './pages/audit';

:root {
  --block-space-between: 160px;

  @include max-width($breakpoint-tablet-lg) {
    --block-space-between: 120px;
  }

  @include max-width($breakpoint-mobile-lg) {
    --block-space-between: 100px;
  }
}

body {
  color: #625C68;
}

::selection {
  background-color: var(--accent-color);
}