.page.page_article {
  .header + .article {
    margin-top: 100px;

    @include max-width($breakpoint-tablet-lg) {
      margin-top: 90px;
    }

    @include max-width($breakpoint-mobile-lg) {
      margin-top: 70px;
    }
  }

  .article + .article-carousel,
  .article-carousel + .consultation {
    margin-top: var(--block-space-between);
  }
}

.article-carousel {
  width: 100%;
  overflow-x: hidden;

  &__wrapper {
    display: flex;
  }

  &__slide {
    flex-shrink: 0;

    aspect-ratio: 16 / 9;
    object-fit: cover;

    cursor: pointer;
    user-select: none;

    border-radius: 10px;

    @include max-width($breakpoint-mobile-lg) {
      aspect-ratio: 1;
    }
  }
}
