.container {
  width: 100%;
  max-width: 1200px;
  margin-inline: auto;

  @include max-width($breakpoint-tablet-lg) {
    max-width: 960px;
    padding-inline: 30px;
  }

  @include max-width($breakpoint-tablet-md) {
    max-width: 768px;
    padding-inline: 30px;
  }

  @include max-width($breakpoint-mobile-lg) {
    max-width: 480px;
    padding-inline: 20px;
  }

  @include max-width($breakpoint-mobile-md) {
    max-width: 360px;
    padding-inline: 20px;
  }
}