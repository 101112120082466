.projects {
  margin-bottom: 160px;

  &__title {
    font-size: 60px;
    font-weight: 400;
    color: #231f29;

    @include max-width($breakpoint-tablet-lg) {
      font-size: 46px;
    }

    @include max-width($breakpoint-mobile-lg) {
      font-size: 36px;
    }
  }

  &__body {
    margin-top: 60px;

    display: flex;
    flex-direction: column;
    row-gap: 60px;

    @include max-width($breakpoint-tablet-lg) {
      margin-top: 50px;
      row-gap: 70px;
    }

    @include max-width($breakpoint-tablet-md) {
      row-gap: 50px;
    }

    @include max-width($breakpoint-mobile-lg) {
      margin-top: 40px;
    }
  }

  &-item {
    &__title {
      font-size: 30px;
      font-weight: 500;
      text-transform: uppercase;
      color: #231f29;

      @include max-width($breakpoint-tablet-lg) {
        font-size: 24px;
      }

      @include max-width($breakpoint-tablet-md) {
        font-size: 20px;
      }

      @include max-width($breakpoint-mobile-lg) {
        font-size: 18px;
      }
    }

    &__divider {
      margin-top: 20px;
    }

    &__body {
      margin-top: 30px;

      display: flex;
      align-items: center;
      justify-content: space-between;

      flex-wrap: wrap;
      gap: 60px;

      @include max-width($breakpoint-tablet-lg) {
        justify-content: unset;
      }

      @include max-width($breakpoint-tablet-md) {
        column-gap: 50px;
      }

      @include max-width($breakpoint-mobile-lg) {
        justify-content: center;
        gap: 30px;
      }
    }

    &__link {
      aspect-ratio: 3;
      height: 110px;

      svg {
        width: 100%;
        height: 100%;
      }

      @include max-width($breakpoint-tablet-md) {
        aspect-ratio: 1.5;
      }

      @include max-width($breakpoint-mobile-md) {
        aspect-ratio: 1;
      }
    }

    &:nth-child(2n) &__link {
      aspect-ratio: 2;

      @include max-width($breakpoint-tablet-md) {
        aspect-ratio: 1.5;
      }

      @include max-width($breakpoint-mobile-md) {
        aspect-ratio: 1;
      }
    }
  }
}