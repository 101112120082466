.dropdown {
  position: relative;

  &-button {
    display: flex;
    align-items: center;
    column-gap: 10px;

    cursor: pointer;
    user-select: none;

    &__icon {
      flex-shrink: 0;
    }
  }

  &-menu {
    visibility: hidden;
    padding: 15px;

    position: absolute;
    right: 50%;
    top: calc(100% + 25px);
    translate: 50% 0;

    display: flex;
    flex-direction: column;
    width: max-content;

    background-color: #231F29;

    &__item {
      padding: 10px;
    }
  }

  &_expanded &-menu {
    visibility: visible;
  }
}
