.areas-of-work {
  display: flex;
  flex-direction: column;
  row-gap: 40px;

  &__title {
    font-size: 46px;
    font-weight: 400;
    color: #231F29;

    @include max-width($breakpoint-tablet-lg) {
      font-size: 46px;
    }

    @include max-width($breakpoint-mobile-lg) {
      font-size: 36px;
    }
  }

  &__body {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 60px;

    @include max-width($breakpoint-tablet-lg) {
      grid-template-columns: repeat(2, 1fr);
      gap: 45px;
    }

    @include max-width($breakpoint-mobile-lg) {
      grid-template-columns: 1fr;
      row-gap: 50px;
    }
  }

  &-item {
    display: flex;
    column-gap: 25px;

    &__icon {
      flex-shrink: 0;

      width: 60px;
      height: 60px;

      svg {
        width: 100%;
        height: 100%;
      }
    }

    &__body {
      display: flex;
      flex-direction: column;
      row-gap: 12.5px;
    }

    &__title {
      font-size: 24px;
      font-weight: 600;
      color: #231F29;

      @include max-width($breakpoint-tablet-lg) {
        font-size: 20px;
      }
    }

    &__description {
      @include max-width($breakpoint-mobile-lg) {
        font-size: 14px;
      }
    }
  }
}
