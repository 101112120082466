.consultation {
  padding-block: 150px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url('/assets/images/hero.png');

  @include max-width($breakpoint-tablet-lg) {
    padding-block: 75px;
  }

  > .container {
    display: flex;
    align-items: center;
    column-gap: 60px;

    @include max-width($breakpoint-mobile-lg) {
      flex-direction: column;
    }
  }

  &-body {
    color: #FFFFFF;

    &__title {
      font-size: 60px;
      font-weight: 400;
    }

    &__subtitle {
      margin-top: 50px;

      font-size: 24px;
      font-weight: 500;
    }

    @include max-width($breakpoint-tablet-lg) {
      &__title {
        font-size: 46px;
      }

      &__subtitle {
        margin-top: 30px;
        font-size: 20px;
        font-weight: 400;
      }
    }

    @include max-width($breakpoint-mobile-lg) {
      &__title {
        font-size: 36px;
      }

      &__subtitle {
        font-size: 16px;
      }
    }
  }

  &-form {
    display: flex;
    flex-direction: column;

    row-gap: 15px;
    flex-basis: 40%;
    flex-shrink: 0;

    &__input,
    &__button {
      width: 100%;
    }

    &__agreement {
      font-size: 12px;
      font-weight: 400;
      color: #FFF;
    }

    @include max-width($breakpoint-tablet-lg) {
      flex-basis: 50%;
    }

    @include max-width($breakpoint-mobile-lg) {
      margin-top: 30px;
    }
  }
}
