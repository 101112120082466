.table {
  width: 100%;
  border-collapse: collapse;

  font-size: 24px;
  font-weight: 400;

  color: #231F29;

  @include max-width($breakpoint-tablet-lg) {
    font-size: 20px;
  }

  @include max-width($breakpoint-tablet-md) {
    font-size: 18px;
  }

  @include max-width($breakpoint-mobile-lg) {
    font-size: 16px;
  }

  @include max-width($breakpoint-mobile-md) {
    font-size: 14px;
  }

  tr {
    td {
      position: relative;
      padding: 20px;


      &:not(:first-child)::before {
        position: absolute;
        content: '';

        top: 50%;
        left: 0;

        width: 4px;
        height: 60%;
        background-color: #C7B6E4;

        translate: -50% -50%;
        border-radius: 10px;
      }
    }

    &:not(:last-child) {
      border-bottom: 2px solid #C7B6E4;
    }
  }
}
