.button {
  width: fit-content;
  padding: 15px 40px;

  font-weight: 500;
  line-height: 1;
  text-align: center;

  color: #FFF;
  background-color: var(--accent-color);

  border-radius: 50px;

  cursor: pointer;
  user-select: none;

  &:hover,
  &:focus-visible {
    background-color: #681BB6;
  }

  &_2 {
    padding: 10px 25px;

    &:hover,
    &:focus-visible {
      background-color: var(--accent-color);
    }
  }

  &_3 {
    padding: 15px 30px;

    display: flex;
    align-items: center;
    justify-content: center;
    column-gap: 20px;

    font-size: 18px;
    background-color: #8265b5;

    > .button__icon {
      width: 20px;
      height: 15px;

      svg {
        width: 100%;
        height: 100%;
      }
    }

    &:hover,
    &:focus-visible {
      background-color: var(--accent-color);
    }
  }

  &_4 {
    background-color: #681BB6;

    &:hover,
    &:focus-visible {
      color: #681BB6;
      background-color: #FAF4FF;
    }
  }
}
