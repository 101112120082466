.hamburger-menu {
  position: fixed;
  padding: 50px;

  top: 0;
  right: 0;

  display: flex;
  flex-direction: column;

  width: 100%;
  height: 100%;

  background-color: #231F29;

  &__item {
    padding: 25px 0;
    font-size: 18px;
    font-weight: 500;
    color: #FFF;
  }

  .dropdown-menu {
    z-index: 1337;

    top: 100%;
    right: 0;
    width: 100%;
    translate: none;

    color: #231F29;
    background-color: #FFF;
  }
}
