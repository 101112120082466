.page.page_pricelist {
  .hero + .page__table,
  .page__table + .projects,
  .projects + .consultation,
  .consultation + .text-block {
    margin-top: var(--block-space-between);
  }
}

.page__table .table {
  td:nth-child(4) {
    display: none;
  }

  @include max-width($breakpoint-mobile-lg) {
    td:nth-child(4) {
      display: table-cell;
      white-space: nowrap;
    }

    td:nth-child(2),
    td:nth-child(3) {
      display: none;
    }
  }
}
