.hero {
	padding-top: 125px;
	--padding-bottom: 125px;

	background-size: cover;
	background-position: center;
	background-repeat: no-repeat;
	background-image: url('/assets/images/hero.png');

	&-header {
		width: fit-content;

		display: flex;
		flex-direction: column;
		row-gap: 40px;

		color: #FAF4FF;

		@include max-width($breakpoint-tablet-lg) {
			padding-bottom: 0;
		}

		&__title {
			font-size: 64px;
			font-weight: 600;
		}

		&__subtitle {
			font-size: 28px;

			&__default {
				display: block;
			}

			&__alternative {
				display: none;
			}
		}
	}

	&:not(:has(.hero-cards-wrapper)) {
		padding-bottom: 125px;
		--padding-bottom: 125px;

		@include max-width($breakpoint-tablet-lg) {
			padding-bottom: 80px;
			--padding-bottom: 80px;
		}

		@include max-width($breakpoint-mobile-lg) {
			padding-bottom: 70px;
			--padding-bottom: 70px;
		}
	}

	&-cards {
		display: grid;
		grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
		gap: 20px;

		width: 100%;
		margin-top: 50px;

		@include max-width($breakpoint-tablet-lg) {
			grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
		}

		@include max-width($breakpoint-mobile-lg) {
			grid-template-columns: 1fr;
		}

		&-wrapper {
			position: relative;
		}

		&__text-item {
			font-family: 'Source Serif 4', serif;
			font-size: 46px;

			color: #FFF;

			@include max-width($breakpoint-tablet-lg) {
				font-size: 40px;
			}

			@include max-width($breakpoint-mobile-lg) {
				font-size: 36px;
			}
		}

		&__item {
			padding: 40px;

			border-radius: 20px;
			background-color: rgba(242, 234, 255, 0.6);

			backdrop-filter: blur(35px);
			box-shadow: 0px 18px 40px 0px rgba(39, 0, 107, 0.18);

			color: #231F29;

			&:has(.hero-card-2) {
				@include max-width($breakpoint-mobile-lg) {
					flex-basis: 100%;
				}
			}
		}
	}

	@include max-width($breakpoint-tablet-lg) {
		padding-top: 80px;
		padding-bottom: 80px;

		&-header {
			row-gap: 30px;

			&__title {
				font-size: 54px;
			}

			&__subtitle {
				font-size: 24px;

				&__default {
					display: none;
				}

				&__alternative {
					display: block;
				}
			}
		}

		&-cards {

			&__item {
				padding: 30px;
			}
		}
	}

	@include max-width($breakpoint-tablet-md) {
		&-header {
			row-gap: 20px;

			&__title {
				font-size: 40px;
			}

			&__subtitle {
				font-size: 20px;
			}
		}

		&-cards {
			grid-template-columns: repeat(1, 1fr);
		}
	}

	@include max-width($breakpoint-mobile-lg) {
		padding-top: 70px;
		padding-bottom: 70px;

		&-header {
			&__title {
				font-size: 36px;
			}

			&__subtitle {
				font-size: 18px;
			}
		}

		&-cards {
			&__item {
				padding: 25px;
			}
		}
	}
}

.audit {
	&-marketing {
		padding-bottom: 160px;

		&__title {
			font-size: 60px;
			font-family: "Source Serif 4", serif;
			font-weight: 400;
			color: #231F29;
		}

		&__divider {
			margin-top: 20px;
		}

		&__items {
			display: flex;
			flex-direction: column;
			margin-top: 40px;
			gap: 20px;

			&__description {
				margin-bottom: 40px;
			}
		}

		&__item {

			background-color: #FAF4FF;

			padding: 20px 20px 20px 30px;
			border-radius: 30px;

			&__header {
				display: flex;
				justify-content: space-between;
				align-items: center;
				color: #231F29;
				padding: 9px 0;
				gap: 20px;

				transition: transform 0.3s ease;

				& svg {
					max-width: 40px;
					width: 100%;
					transition: transform 0.3s ease;

					& .active {
						transform: rotate(180deg);
					}
				}

				& .active {
					transform: rotate(180deg);
				}
			}

			&__body {
				display: none;
				padding-right: 50px;
				color: #231F29;
				font-weight: 400;

				strong {
					font-weight: 500;
				}
			}
		}

		@include max-width($breakpoint-tablet-lg) {
			&__title {
				font-size: 46px;
			}
		}
	}
}