.page.page_countryside-hotel {
  .hero + .page__tabs,
  .page__tabs + .page__accordion,
  .page__accordion + .page__partner,
  .page__partner + .projects,
  .projects + .consultation,
  .consultation + .text-block {
    margin-top: var(--block-space-between);
  }
}

.page__tabs {
  @include max-width($breakpoint-mobile-lg) {
    display: none;
  }
}

.page__accordion {
  @include min-width($breakpoint-mobile-lg) {
    display: none;
  }
}

.marketing-management {
  display: flex;
  flex-direction: column;
  row-gap: 35px;

  @include max-width($breakpoint-tablet-md) {
    row-gap: 30px;
  }

  &-item {
    display: flex;
    flex-direction: column;
    row-gap: 5px;
    color: #231F29;

    &__title {
      font-size: 24px;
      font-weight: 600;

      @include max-width($breakpoint-tablet-md) {
        font-size: 20px;
        color: #8265B5;
      }
    }
  }
}

.partner {
  display: flex;
  flex-direction: column;
  row-gap: 40px;

  &-header {
    display: flex;
    align-items: center;
    column-gap: 50px;

    @include max-width($breakpoint-tablet-md) {
      column-gap: 30px;
    }

    @include max-width($breakpoint-mobile-lg) {
      flex-direction: column;
      row-gap: 30px;
    }

    &-body {
      display: flex;
      flex-direction: column;

      &__description {
        margin-top: 60px;

        font-size: 24px;
        font-weight: 130%;

        @include max-width($breakpoint-tablet-lg) {
          margin-top: 40px;
          font-size: 20px;
        }

        @include max-width($breakpoint-tablet-md) {
          margin-top: 30px;
          font-size: 18px;
        }
      }
    }

    &__image {
      flex-shrink: 0;

      width: calc(50% - 25px);
      height: 280px;

      object-fit: cover;
      border-radius: 20px;

      @include max-width($breakpoint-tablet-md) {
        height: 240px;
        width: calc(50% - 15px);
      }

      @include max-width($breakpoint-mobile-lg) {
        width: 100%;
        aspect-ratio: 16 / 9;
      }
    }
  }

  &-body {
    display: flex;
    flex-direction: column;
    row-gap: 20px;

    &__title {
      font-family: 'Avenir Next', sans-serif;
      font-size: 32px;
      font-weight: 600;
      color: #231F29;

      @include max-width($breakpoint-tablet-lg) {
        font-size: 28px;
      }

      @include max-width($breakpoint-mobile-lg) {
        font-size: 20px;
      }
    }

    &__description {
      font-size: 24px;
      line-height: 130%;

      @include max-width($breakpoint-tablet-lg) {
        font-size: 18px;
      }
    }

    &__list {
      padding-left: 200px;

      .list__item {
        @include max-width($breakpoint-tablet-lg) {
          font-size: 16px;
        }
      }

      @include max-width($breakpoint-tablet-lg) {
        padding-left: 0;
      }
    }
  }
}
