.channels {
  display: flex;
  flex-direction: column;
  row-gap: 40px;

  &__title {
    font-size: 46px;
    font-weight: 400;
    color: #231F29;

    @include max-width($breakpoint-tablet-lg) {
      font-size: 46px;
    }

    @include max-width($breakpoint-mobile-lg) {
      font-size: 36px;
    }
  }

  &__items {
    display: flex;
    flex-wrap: wrap;
    gap: 30px 60px;
  }

  &-item {
    width: fit-content;

    &__icon {
      width: 200px;
      height: 200px;

      @include max-width($breakpoint-tablet-lg) {
        width: 120px;
        height: 120px;
      }

      svg {
        width: 100%;
        height: 100%;
      }
    }

    &__title {
      font-size: 28px;
      font-weight: 600;
      color: #231F29;

      @include max-width($breakpoint-tablet-lg) {
        font-size: 24px;
      }
    }

    &__list {
      margin-top: 30px;
    }
  }
}
