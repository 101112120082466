.article {
  display: flex;
  flex-direction: column;
  row-gap: 40px;

  &-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    column-gap: 40px;

    @include max-width($breakpoint-mobile-md) {
      align-items: normal;
      flex-direction: column;
      row-gap: 10px;
    }

    &__title {
      font-size: 46px;
      font-weight: 400;
      color: #231F29;

      @include max-width($breakpoint-tablet-lg) {
        font-size: 46px;
      }

      @include max-width($breakpoint-mobile-lg) {
        font-size: 36px;
      }
    }

    &__button {
      flex-shrink: 0;
      padding: 20px 30px;

      display: flex;
      align-items: center;
      column-gap: 20px;

      background-color: #681BB6;

      @include max-width($breakpoint-mobile-md) {
        margin-left: auto;
      }
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    row-gap: 30px;

    font-size: 20px;
    color: #231F29;

    @include max-width($breakpoint-mobile-lg) {
      font-size: 16px;
    }

    b {
      font-weight: 600;
    }

    ul {
      margin-block: 0;
      padding-left: 18px;

      li {
        &::marker {
          color: #8265B5;
        }
      }
    }
  }

  &-content-block {
    display: grid;
    grid-template-columns: .5fr 1fr;
    gap: 10px 55px;

    @include max-width($breakpoint-tablet-lg) {
      grid-template-columns: .7fr 1fr;
    }

    @include max-width($breakpoint-mobile-lg) {
      grid-template-columns: 1fr;
    }

    &__title {
      font-size: 24px;
      font-weight: 400;
      color: #8265B5;

      @include max-width($breakpoint-tablet-lg) {
        font-size: 22px;
      }
    }

    &__body {
      display: flex;
      flex-direction: column;
      row-gap: 20px;
      font-size: 16px;
    }
  }
}
