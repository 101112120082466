.tabs {
  &-header {
    display: flex;
    column-gap: 10px;

    &__button {
      width: min-content;
      padding: 15px 40px;

      font-size: 20px;
      text-align: left;

      border-top-right-radius: 20px;
      border-top-left-radius: 20px;

      color: #231F29;
      background-color: #FAF4FF;

      cursor: pointer;
      user-select: none;

      &_active {
        color: #FFF;
        background-color: #8265B5;
      }

      @include max-width($breakpoint-tablet-md) {
        padding: 10px 20px;
        font-size: 18px;
      }
    }
  }

  &__item {
    padding: 40px 40px 65px 40px;
    border-top: 6px solid #8265B5;
    border-radius: 0 20px 20px 20px;
    background-color: #FAF4FF;
  }
}
