.hero-card-1 {
  display: flex;
  flex-direction: column;
  height: 100%;

  &__title {
    font-size: 26px;
    font-weight: 600;
    color: #231f29;
  }

  &__list {
    margin-block: 30px 15px;
  }

  &-footer {
    margin-top: auto;

    display: flex;
    align-items: center;
    justify-content: space-between;

    &__number {
      font-family: 'Source Serif 4', serif;
      font-size: 70px;
      font-weight: 700;

      line-height: 1;
      color: rgba(104, 27, 182, 0.06);
    }
  }

  @include max-width($breakpoint-tablet-lg) {
    &__title {
      font-size: 18px;
    }

    &__list {
      margin-block: 20px;

      .list__item {
        font-size: 16px;
      }
    }

    &-footer {
      &__button {
        font-size: 14px;
      }

      &__number {
        display: none;
      }
    }
  }

  @include max-width($breakpoint-mobile-lg) {
    &__list .list__item {
      column-gap: 10px;
      font-size: 14px;

      &::before {
        width: 8px;
        height: 8px;
      }
    }
  }
}
