* {
  -webkit-tap-highlight-color: transparent;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

html {
  box-sizing: border-box;
}

body {
  margin: 0;
}

svg {
  display: block;
}

img {
  display: block;
  max-width: 100%;
}

a {
  color: inherit;
  text-decoration: inherit;
}

button,
input {
  padding: 0;

  display: inline-block;
  width: fit-content;

  font: inherit;

  color: inherit;
  background: none;

  border: none;
  outline: none;
}

h1,
h2,
h3,
p {
  margin-block: 0;
}
