.accordion {
  display: flex;
  flex-direction: column;
  row-gap: 20px;

  &-item {
    &-button {
      width: 100%;
      padding: 10px 10px 10px 30px;

      display: flex;
      align-items: center;
      justify-content: space-between;

      border-radius: 30px;
      border: 1px solid #C7B6E4;

      text-align: left;
      background-color: #FAF4FF;

      cursor: pointer;
      user-select: none;

      &__title {
        font-size: 22px;
        font-weight: 600;
        color: #231F29;

        @include max-width($breakpoint-mobile-md) {
          font-size: 17px;
        }
      }
    }

    &-body {
      max-height: 0;
      overflow-y: hidden;

      &__inner {
        padding: 10px 10px 30px 30px;
        background-color: #FAF4FF;

        border: 1px solid #C7B6E4;
        border-top: none;

        border-bottom-right-radius: 20px;
        border-bottom-left-radius: 20px;

        @include max-width($breakpoint-mobile-md) {
          padding: 10px 10px 30px 20px;
        }
      }
    }

    &_expanded &-button {
      border-bottom: transparent;
      border-bottom-right-radius: 0;
      border-bottom-left-radius: 0;
    }

    &_expanded &-button-icon &-button-icon__collapsed {
      display: none;
    }

    &:not(.accordion-item_expanded) &-button-icon &-button-icon__expanded {
      display: none;
    }

    &_expanded &-body {
      max-height: 100vh;
    }
  }
}
