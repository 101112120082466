.page.page_portfolio {
  .hero + .marketing-research,
  .marketing-research + .marketing-management-section,
  .marketing-management-section + .digital-body,
  .digital-body + .consultation,
  .consultation + .blog,
  .blog + .tags {
    margin-top: var(--block-space-between);
  }
}

.portfolio-block {
  &-title {
    display: flex;
    align-items: center;
    justify-content: space-between;

    @include max-width($breakpoint-mobile-md) {
      align-items: flex-start;
      flex-direction: column-reverse;
      row-gap: 10px;
    }

    &__text {
      font-size: 60px;
      font-weight: 400;
      color: #231F29;

      @include max-width($breakpoint-tablet-lg) {
        font-size: 46px;
      }

      @include max-width($breakpoint-mobile-lg) {
        font-size: 36px;
      }
    }

    &__icon svg {
      @include max-width($breakpoint-tablet-lg) {
        width: 120px;
        height: 120px;
      }
    }
  }

  &__divider {
    margin-top: 5px;

    @include max-width($breakpoint-mobile-md) {
      margin-top: 30px;
    }
  }

  &__body {
    margin-top: 60px;

    @include max-width($breakpoint-mobile-md) {
      margin-top: 30px;
    }
  }
}

.marketing-research {
  &__body {
    display: flex;
    column-gap: 60px;
    justify-content: space-between;

    @include max-width($breakpoint-tablet-lg) {
      column-gap: 40px;
    }

    @include max-width($breakpoint-mobile-lg) {
      flex-direction: column;
      row-gap: 40px;
    }
  }

  &-item {
    &__title {
      font-size: 28px;
      font-weight: 600;
      color: #231F29;

      @include max-width($breakpoint-tablet-lg) {
        font-size: 24px;
      }
    }

    &__list {
      margin-top: 30px;
    }
  }
}

.marketing-management-section {
  &__body {
    display: flex;
    flex-direction: column;
    row-gap: 90px;
  }

  &-block {
    &__title {
      font-size: 36px;
      font-weight: 400;
      text-transform: uppercase;
      color: #231F29;

      @include max-width($breakpoint-tablet-lg) {
        font-size: 28px;
      }
    }

    &__items {
      display: flex;
      gap: 60px 40px;
      margin-top: 60px;

      @include max-width($breakpoint-mobile-lg) {
        row-gap: 40px;
        margin-top: 40px;
        flex-direction: column;
      }
    }

    &__items-column {
      display: flex;
      flex-direction: column;
      row-gap: 60px;
    }
  }

  &-item {
    padding-bottom: 60px;
    height: fit-content;

    display: flex;
    flex-direction: column;
    row-gap: 20px;

    border-bottom: 1px solid #B063FF;

    @include max-width($breakpoint-mobile-lg) {
      padding-bottom: 40px;
    }

    &__title {
      font-size: 28px;
      font-weight: 600;
      color: #231F29;

      @include max-width($breakpoint-tablet-lg) {
        font-size: 24px;
      }
    }
  }
}

.digital-body {
  &__body {
    display: flex;
    flex-direction: column;
    row-gap: 100px;

    @include max-width($breakpoint-tablet-md) {
      row-gap: 60px;
    }

    @include max-width($breakpoint-mobile-lg) {
      row-gap: 100px;
    }
  }

  &-block {
    &__title {
      font-size: 28px;
      font-weight: 400;
      text-transform: uppercase;
      color: #231F29;

      @include max-width($breakpoint-mobile-md) {
        font-size: 24px;
      }
    }

    &__items {
      display: grid;
      grid-template-columns: repeat(2, 1fr);

      gap: 40px;
      margin-top: 20px;

      @include max-width($breakpoint-tablet-lg) {
        gap: 30px;
        margin-top: 30px;
      }

      @include max-width($breakpoint-tablet-md) {
        gap: 20px;
      }

      @include max-width($breakpoint-mobile-lg) {
        grid-template-columns: 1fr;
        margin-top: 10px;
      }

      &_3 {
        grid-template-columns: repeat(3, 1fr) !important;

        @include max-width($breakpoint-mobile-md) {
          grid-template-columns: 1fr !important;
        }

        .digital-body-item-carousel-slide {
          aspect-ratio: .5;
        }
      }
    }
  }

  &-item {
    min-width: 100%;

    &__icon {
      margin-bottom: 20px;

      @include max-width($breakpoint-mobile-lg) {
        margin-bottom: 10px;
      }
    }

    &-carousel {
      position: relative;

      width: 100%;
      overflow-x: hidden;

      border-radius: 20px;
      box-shadow: 0px 30px 30px -24px #573E8499;

      &__wrapper {
        display: flex;
      }

      &-slide {
        flex-shrink: 0;
        aspect-ratio: 1.35;

        &__image {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }

      &-buttons {
        position: absolute;

        bottom: 30px;
        left: 30px;

        display: flex;
        column-gap: 20px;

        @include max-width($breakpoint-tablet-md) {
          bottom: 10px;
          left: 10px;
        }

        &__item {
          display: flex;
          align-items: center;
          justify-content: center;

          width: 40px;
          height: 40px;

          background-color: #FFF;
          box-shadow: 0px 6px 20px 0px #27006B4D;

          border-radius: 100%;

          cursor: pointer;
          user-select: none;
        }
      }
    }
  }
}
