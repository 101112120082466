.text-block {
  display: flex;
  flex-direction: column;
  row-gap: 40px;

  &__section {
    margin-bottom: 160px;

    h2 {
      font-family: "Avenir Next", sans-serif;
      font-size: 28px;
      font-weight: 600;
    }

    @include max-width($breakpoint-tablet-lg) {
      padding: 70px 0;
      margin-bottom: 0;
    }
  }

  &__title {
    font-size: 46px;
    font-weight: 400;
    color: #231F29;

    @include max-width($breakpoint-mobile-lg) {
      font-size: 36px;
    }
  }

  &__content {
    padding-left: 200px;
    font-size: 18px;

    >.list {
      row-gap: 20px;
    }

    >b {
      color: #231F29;
    }

    @include max-width($breakpoint-tablet-lg) {
      padding-left: 0;
    }
  }
}