.footer {
  padding-block: 100px;
  color: #FFF;
  background-color: #231F29;

  @include max-width($breakpoint-tablet-lg) {
    padding-block: 75px;
  }

  > .container {
    display: flex;
    align-items: center;
    column-gap: 70px;

    @include max-width($breakpoint-tablet-lg) {
      flex-direction: column;
      align-items: flex-start;
      row-gap: 30px;
    }
  }

  &__header {
    display: flex;
    column-gap: 50px;

    .footer-navigation {
      flex-direction: column;
      align-items: flex-start;
      row-gap: 10px;

      @include min-width($breakpoint-mobile-lg) {
        display: none;
      }
    }
  }

  &__logotype {
    svg {
      width: 225px;
      height: 225px;

      @include max-width($breakpoint-tablet-lg) {
        width: 95px;
        height: 95px;
      }

      @include max-width($breakpoint-mobile-lg) {
        width: 150px;
        height: 150px;
      }
    }
  }

  &__wrapper {
    display: flex;
    flex-direction: column;
    row-gap: 20px;
    width: 100%;

    .footer-navigation {
      @include max-width($breakpoint-mobile-lg) {
        display: none;
      }
    }
  }

  &-navigation {
    display: flex;
    align-items: center;
    column-gap: 20px;

    &__item {
      font-size: 14px;

      @include max-width($breakpoint-tablet-lg) {
        font-size: 12px;
      }
    }

    &__button {
      @include max-width($breakpoint-tablet-lg) {
        display: none;
      }
    }
  }

  &__wrapper-2 {
    display: flex;
    justify-content: space-between;

    @include max-width($breakpoint-tablet-md) {
      flex-direction: column;
      row-gap: 30px;
    }
  }

  &-contacts {
    display: flex;
    flex-direction: column;
    row-gap: 15px;

    &-item {
      display: flex;
      column-gap: 10px;
      font-size: 14px;

      &__icon {
        color: var(--accent-color);

        svg {
          width: 16px;
          height: 16px;
        }
      }

      &__text {
        font-weight: 400;
      }
    }
  }

  &__footer {
    display: grid;
    grid-template-columns: 1fr;
    gap: 15px;
    place-items: end;

    @include max-width($breakpoint-tablet-md) {
      grid-template-columns: 1fr 1fr;
      place-items: unset;
    }

    @include max-width($breakpoint-mobile-lg) {
      grid-template-columns: 1fr;
    }
  }

  &__copyright {
    font-size: 12px;

    @include max-width($breakpoint-tablet-md) {
      grid-column: 1;
      grid-row: 2;
    }
  }

  &__link {
    font-size: 12px;
    text-decoration: underline;

    @include max-width($breakpoint-tablet-md) {
      grid-column: 1;
      grid-row: 1;
    }
  }

  &__footer-button {
    @include max-width($breakpoint-tablet-md) {
      margin: auto 0 auto auto;
      grid-column: 2;
      grid-row: 1 / 3;
    }

    @include max-width($breakpoint-mobile-lg) {
      margin: 0;
      grid-column: 1;
      grid-row: 3;
    }
  }
}
