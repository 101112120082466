.team {
  display: flex;
  flex-direction: column;
  row-gap: 40px;

  &__title {
    font-size: 60px;
    font-weight: 400;
    color: #231f29;

    @include max-width($breakpoint-tablet-lg) {
      font-size: 46px;
    }

    @include max-width($breakpoint-mobile-lg) {
      font-size: 36px;
    }
  }

  &__description {
    font-size: 24px;
    text-align: right;

    @include max-width($breakpoint-tablet-lg) {
      font-size: 20px;
      text-align: left;
    }

    @include max-width($breakpoint-mobile-lg) {
      font-size: 18px;
    }
  }

  &__body {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 60px 20px;

    @include max-width($breakpoint-tablet-lg) {
      grid-template-columns: repeat(3, 1fr);
      gap: 40px 30px;
    }

    @include max-width($breakpoint-tablet-md) {
      row-gap: 30px;
    }

    @include max-width($breakpoint-mobile-lg) {
      grid-template-columns: repeat(2, 1fr);
      gap: 20px;
    }
  }

  &-item {
    display: flex;
    flex-direction: column;

    &__image {
      width: 100%;

      aspect-ratio: 1;
      object-fit: cover;

      border-radius: 10px;
    }

    &__title {
      margin-top: 20px;

      font-size: 22px;
      font-weight: 600;
      text-align: center;

      color: #231f29;

      @include max-width($breakpoint-tablet-lg) {
        font-size: 20px;
      }
    }

    &__subtitle {
      margin-top: 10px;
      text-align: center;

      @include max-width($breakpoint-tablet-lg) {
        font-size: 14px;
      }
    }
  }
}
