@mixin max-width($media) {
  @media (max-width: $media) {
    @content;
  }
}

@mixin min-width($media) {
  @media (min-width: ($media + 1)) {
    @content;
  }
}
