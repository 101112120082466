@font-face {
  font-family: 'Source Serif 4';
  src: url('/assets/fonts/dehinted-SourceSerif4-SemiboldIt.woff2') format('woff2');
  font-weight: 600;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Source Serif 4';
  src: url('/assets/fonts/dehinted-SourceSerif4-LightIt.woff2') format('woff2');
  font-weight: 300;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Source Serif 4';
  src: url('/assets/fonts/dehinted-SourceSerif4-It.woff2') format('woff2');
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Source Serif 4';
  src: url('/assets/fonts/dehinted-SourceSerif4-Black.woff2') format('woff2');
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Source Serif 4';
  src: url('/assets/fonts/dehinted-SourceSerif4-Regular.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Source Serif 4';
  src: url('/assets/fonts/dehinted-SourceSerif4-ExtraLightIt.woff2') format('woff2');
  font-weight: 200;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Avenir Next';
  src: url('/assets/fonts/dehinted-AvenirNextCyr-Regular.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Avenir Next';
  src: url('/assets/fonts/dehinted-AvenirNextCyr-Demi.woff2') format('woff2');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Avenir Next';
  src: url('/assets/fonts/dehinted-AvenirNextCyr-Medium.woff2') format('woff2');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Avenir Next Cyr Ultra';
  src: url('/assets/fonts/dehinted-AvenirNextCyr-UltraLight.woff2') format('woff2');
  font-weight: 200;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Avenir Next';
  src: url('/assets/fonts/dehinted-AvenirNextCyr-ThinItalic.woff2') format('woff2');
  font-weight: 100;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Avenir Next';
  src: url('/assets/fonts/dehinted-AvenirNextCyr-MediumItalic.woff2') format('woff2');
  font-weight: 500;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Source Serif 4';
  src: url('/assets/fonts/dehinted-SourceSerif4-BoldIt.woff2') format('woff2');
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Avenir Next';
  src: url('/assets/fonts/dehinted-AvenirNextCyr-Italic.woff2') format('woff2');
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Avenir Next';
  src: url('/assets/fonts/dehinted-AvenirNextCyr-DemiItalic.woff2') format('woff2');
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Avenir Next';
  src: url('/assets/fonts/dehinted-AvenirNextCyr-Heavy.woff2') format('woff2');
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Source Serif 4';
  src: url('/assets/fonts/dehinted-SourceSerif4-Semibold.woff2') format('woff2');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Source Serif 4';
  src: url('/assets/fonts/dehinted-SourceSerif4-Light.woff2') format('woff2');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Avenir Next';
  src: url('/assets/fonts/dehinted-AvenirNextCyr-LightItalic.woff2') format('woff2');
  font-weight: 300;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Source Serif 4';
  src: url('/assets/fonts/dehinted-SourceSerif4-BlackIt.woff2') format('woff2');
  font-weight: 900;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Avenir Next Cyr Ultra';
  src: url('/assets/fonts/dehinted-AvenirNextCyr-UltraLightIt.woff2') format('woff2');
  font-weight: 200;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Avenir Next';
  src: url('/assets/fonts/dehinted-AvenirNextCyr-Thin.woff2') format('woff2');
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Source Serif 4';
  src: url('/assets/fonts/dehinted-SourceSerif4-ExtraLight.woff2') format('woff2');
  font-weight: 200;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Avenir Next';
  src: url('/assets/fonts/dehinted-AvenirNextCyr-Light.woff2') format('woff2');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Avenir Next';
  src: url('/assets/fonts/dehinted-AvenirNextCyr-HeavyItalic.woff2') format('woff2');
  font-weight: 900;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Avenir Next';
  src: url('/assets/fonts/dehinted-AvenirNextCyr-Bold.woff2') format('woff2');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Source Serif 4';
  src: url('/assets/fonts/dehinted-SourceSerif4-Bold.woff2') format('woff2');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

body {
  font-family: 'Avenir Next', sans-serif;
  font-weight: 500;
}

h1,
h2 {
  font-family: 'Source Serif 4', serif;
}
