.hero-card-2 {
  display: flex;
  flex-direction: column;
  row-gap: 10px;

  &__icon {
    width: 100%;
    height: 200px;

    svg {
      width: 100%;
      height: 100%;
    }
  }

  &__title {
    font-size: 24px;
    font-weight: 600;
    color: #231F29;
  }

  &__subtitle {
    font-size: 24px;
    font-weight: 400;
    color: #231F29;
  }

  &__description {
    color: #625C68;
  }
}
