.list {
  display: flex;
  flex-direction: column;
  row-gap: 10px;

  &__item {
    display: flex;
    column-gap: 15px;
    font-size: 18px;

    &::before {
      margin-top: .35em;
      flex-shrink: 0;

      display: block;
      content: '';

      width: 10px;
      height: 10px;

      border-radius: 100%;
      background-color: #8265b5;
    }
  }
}
