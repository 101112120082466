.tags {
  padding-block: 150px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  gap: 10px;

  &__item {
    padding: 10px 15px;

    font-size: 14px;
    border-radius: 6px;

    color: #FFF;
    background-color: #C7B6E4;
  }

  @include max-width($breakpoint-tablet-lg) {
    padding-block: 125px;

    &__item {
      font-size: 12px;
    }
  }

  @include max-width($breakpoint-mobile-lg) {
    padding-block: 100px;

    &__item {
      padding: 5px 10px;
    }
  }
}
