.header {
  padding-block: 10px;

  color: #FFF;
  background-color: #231F29;

  > .container {
    display: flex;
    justify-content: space-between;

    @include max-width($breakpoint-tablet-lg) {
      align-items: center;
    }
  }

  &__logotype-wrapper {
    position: relative;
    z-index: 1;
  }

  &__logotype {
    position: absolute;
    padding: 10px;

    background-color: var(--accent-color);
    box-shadow: 0px 4px 50px 0px rgba(58, 0, 118, 0.4);

    border-radius: 100%;

    svg {
      width: 100px;
      height: 100px;
    }

    @include max-width($breakpoint-tablet-lg) {
      position: static;

      svg {
        width: 60px;
        height: 60px;
      }
    }
  }

  &-contacts {
    display: flex;
    column-gap: 30px;

    &-item {
      display: flex;
      align-items: center;
      column-gap: 15px;

      &__icon {
        flex-shrink: 0;
        color: var(--accent-color);

        svg {
          width: 16px;
          height: 16px;
        }
      }

      &__text {
        font-size: 14px;
        font-weight: 500;
      }

      @include max-width($breakpoint-tablet-md) {
        display: none;
      }
    }

    &__button {
      @include max-width($breakpoint-mobile-lg) {
        display: none;
      }
    }
  }

  &-wrapper {
    padding-block: 5px 10px;

    display: flex;
    flex-direction: column;
    align-items: flex-end;

    row-gap: 20px;

    @include max-width($breakpoint-tablet-lg) {
      padding-block: 0;

      flex-direction: row;
      align-items: center;

      column-gap: 30px;
    }
  }

  &-navigation {
    display: flex;
    column-gap: 30px;
    padding-right: 25px;

    @include max-width($breakpoint-tablet-lg) {
      display: none;
    }
  }

  &__hamburger-button {
    z-index: 1;

    display: none;
    padding: 15px;

    border-radius: 100%;
    background-color: var(--accent-color);

    svg {
      width: 15px;
      height: 15px;
    }

    @include max-width($breakpoint-tablet-lg) {
      display: block;
    }
  }
}
