.scope-of-work {
  &__title {
    font-size: 60px;
    font-weight: 400;
    color: #231F29;

    @include max-width($breakpoint-tablet-lg) {
      font-size: 46px;
    }

    @include max-width($breakpoint-mobile-lg) {
      font-size: 40px;
    }
  }

  &__body {
    display: flex;
    flex-wrap: wrap;
    row-gap: 40px;
    margin-top: 65px;

    @include max-width($breakpoint-tablet-lg) {
      row-gap: 30px;
      margin-top: 40px;
    }
  }

  &-item {
    flex-grow: 1;
    flex-basis: 25%;

    @include max-width($breakpoint-tablet-md) {
      flex-basis: 50%;
    }

    @include max-width($breakpoint-mobile-lg) {
      flex-basis: 100%;
    }

    &-header {
      width: fit-content;

      &__number {
        font-family: 'Source Serif 4', serif;
        font-size: 80px;
        font-weight: 300;
        color: #8265B5;
      }

      &__divider {
        margin-top: 30px;

        width: 100%;
        height: 5px;

        border-top-left-radius: 5px;
        border-top-right-radius: 5px;

        background-color: #B063FF;
      }
    }

    &__title {
      padding-top: 30px;
      padding-right: 40px;

      border-top: 1px solid #B063FF;

      font-size: 28px;
      font-weight: 600;

      color: #231F29;

      @include max-width($breakpoint-tablet-lg) {
        font-size: 24px;
      }
    }

    &__description {
      padding-right: 40px;
      margin-top: 30px;

      font-size: 18px;
      font-weight: 500;

      @include max-width($breakpoint-tablet-lg) {
        font-size: 16px;
      }
    }
  }
}
