.divider {
  width: 100%;
  border-bottom: 2px solid var(--accent-color);

  &::before {
    display: block;
    content: '';

    width: 380px;
    height: 5px;

    border-top-left-radius: 4px;
    border-top-right-radius: 4px;

    background-color: var(--accent-color);

    @include max-width($breakpoint-tablet-lg) {
      width: 180px;
    }

    @include max-width($breakpoint-mobile-lg) {
      width: 80px;
    }
  }
}
